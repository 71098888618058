<template>
  <div ref="mount" class="h-100 w-100"></div>
</template>

<script>
const ace = require('brace')

export default {
  name: 'SemiCodeEditor',
  data () {
    return {
      editor: null,
      defaultEditorOptions: {
        fontSize: 14,
        highlightActiveLine: true,
        printMarginColumn: -1
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    sizeVersion: {
      type: Number,
      default: 1
    },
    focus: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'text'
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    const vm = this
    const editor = vm.editor = ace.edit(vm.$refs.mount)

    editor.$blockScrolling = Infinity

    vm.setEditorOptions(editor)

    editor.setValue(vm.value, 1)

    editor.on('change', () => {
      vm.$emit('input', editor.getValue())
    })

    editor.on('focus', () => {
      editor.resize()
    })

    vm.configureEditor(editor)

    vm.initCommands(editor)

    if (this.focus) {
      editor.focus()
    }
  },
  methods: {
    configureEditor (editor) {
      require('brace/ext/language_tools')
      require('brace/ext/searchbox')
      require('brace/ext/error_marker')
      //
      require('brace/theme/chrome')
      editor.setTheme('ace/theme/chrome')
      //
      if (this.lang) {
        require(`brace/mode/${this.lang}`)
        require(`brace/snippets/${this.lang}`)
        editor.getSession().setMode(`ace/mode/${this.lang}`)
      }
    },
    setEditorOptions (editor) {
      const customEditorOptions = {
        readOnly: this.readOnly
      }
      const options = { ...this.defaultEditorOptions, ...customEditorOptions }
      const editor_ = editor || this.editor
      editor_.setOptions(options)
    },
    initCommands (editor) {
      const vm = this
      editor.commands.addCommand({
        name: 'ctrl-enter',
        bindKey: { win: 'Ctrl-Enter', mac: 'Cmd-Enter' },
        exec: () => {
          vm.$emit('ctrl-enter', editor)
        }
      })
    }
  },
  beforeDestroy () {
    this.editor.destroy()
    this.editor.container.remove()
  },
  watch: {
    value (val) {
      if (this.editor.getValue() !== val) {
        this.editor.session.setValue(val, 1)
      }
    },
    sizeVersion () {
      this.editor.resize()
    },
    lang () {
      this.configureEditor(this.editor)
    },
    readOnly (val) {
      this.editor.setOption('readOnly', val)
    }
  }
}
</script>

<style lang="scss">
.ace_gutter {
  background-color: #fff!important;
  border-right: 1px solid #dee2e6!important;
}
</style>
