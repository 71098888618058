<template>
  <div>
    <a href="#" @click.prevent="toogle">{{ toogleText }}</a>
    <div class="collapse pt-2" :class="{ show: !collapsed }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MSimpleCollapse',
  data () {
    return {
      collapsed: true
    }
  },
  methods: {
    toogle () {
      this.collapsed = !this.collapsed
    }
  },
  computed: {
    toogleText () {
      return this.collapsed ? 'Раскрыть' : 'Скрыть'
    }
  }
}
</script>
