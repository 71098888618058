<template>
  <div>

    <semi-form-header
      :backLink="{ name: 'adminConfigsList' }"
      backLinkText="к списку конфигов"
      class="mb-4"
    >
      {{ name }}
    </semi-form-header>

    <form v-on:submit.prevent="updateConfig" autocomplete="off" class="px-2">

      <div class="form-group row">
        <label
          for="config-description"
          class="col-sm-2 col-form-label">
          Описание</label>
        <div class="col-sm-10">
          <div class="pt-1">{{ description }}</div>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="config-description"
          class="col-sm-2 col-form-label">
          Схема</label>
        <div class="col-sm-10">
          <m-simple-collapse class="pt-2">
            <pre class="bg-light p-2 border border-1"><code>{{ schema }}</code></pre>
          </m-simple-collapse>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="config-source"
          class="col-sm-2 col-form-label">
          Конфиг
        </label>
        <div class="col-sm-10">
          <div class="border">
            <semi-code-editor
              v-model="source"
              ref="editor"
              @ctrl-enter="updateConfig"
              style="min-height: 500px"
              lang="yaml"/>
          </div>
        </div>
      </div>

      <div class="form-group row justify-content-end">
        <div class="col-sm-10">
          <div class="bg-light border rounded p-3 d-flex justify-content-end">
            <button class="btn btn-success">{{ 'Сохранить' }}</button>
          </div>
        </div>
      </div>

    </form>

  </div>
</template>

<script>
import SemiCodeEditor from '@/components/CodeEditor'
import SemiFormHeader from '@/components/admin/FormHeader'
import MSimpleCollapse from '@/components/common/SimpleCollapse'

export default {
  data () {
    return {
      name: null,
      description: null,
      schema: null,
      source: '',
      version: null
    }
  },
  computed: {
    configId () {
      return this.$route.params.configId
    }
  },
  methods: {
    updateConfig () {
      this.$store.dispatch('adminConfigs/updateConfig', {
        configId: this.configId,
        data: {
          source_as_yaml: this.source,
          version: this.version
        }
      })
        .then(response => {
          this.notifySuccess({
            title: `Конфиг ${response.data.name} обновлен`
          })
          this.$router.push({ name: 'adminConfigsList' })
        })
        .catch(error => {
          this.notifyError({ message: JSON.stringify(error.response.data) })
        })
    },
    fetchConfig () {
      this.$store.dispatch('adminConfigs/fetchConfig', this.configId)
        .then(response => {
          this.name = response.data.name
          this.description = response.data.description
          this.schema = response.data.schema_as_yaml
          this.source = response.data.source_as_yaml
          this.version = response.data.version
        })
        .catch(() => {
          this.notifyError({ message: 'Ошибка при загрузке конфига' })
        })
    }
  },
  mounted () {
    this.fetchConfig()
  },
  components: {
    SemiCodeEditor,
    SemiFormHeader,
    MSimpleCollapse
  },
  notifications: {
    notifyError: {
      type: 'error',
      title: 'Ошибка',
      message: ''
    },
    notifySuccess: {
      type: 'success',
      title: '',
      message: ''
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
}
</style>
