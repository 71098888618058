<template>
  <div
    class="d-flex mb-4 px-2"
    :class="{ 'border-bottom': !noBorder, 'pb-2': !noBorder }"
  >
    <h4 class="m-0 flex-grow-1">
      <slot></slot>
    </h4>
    <router-link
      v-if="backLink !== null"
      :to="backLink"
      tag="div"
      class="m-0 align-self-end link-back">
      <font-awesome-icon icon="long-arrow-alt-left" class="arrow"/>
      <span>{{ backLinkText }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SemiFormHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    backLink: {
      type: Object,
      default: null
    },
    backLinkText: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.link-back {
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    color: #333;
    .arrow {
      margin-right: 6px;
    }
  }

  .arrow {
    padding-top: 0.2rem;
    margin-right: 3px;
    transition: margin-right 0.1s ease-out;
  }
}
</style>
